.jumbotron {
  background-image: url('./static/blue-lake.jpg');
  background-size: cover;
  height: 100vh;
  background-attachment: fixed;
  padding-right:0;
  padding-left:0;
  margin-right:auto;
  margin-left:auto;
  overflow: auto;
  -webkit-border-radius: 0px;
}

.container-fluid {
  position: relative;
}

.card-text-user {
  color: rgb(15,139,37);
  font-family: monaco;
  font-size: 20px;
  margin: 0;
}

.card-text-result {
  color: rgb(8,168,229);
  font-family: monaco;
  font-size: 17px;
}

.octicon {
  padding-right: 1.1px;
  padding-left: 4.9px;
}

.terminal1-body {
  position: relative;
  padding-left: 16px;
  padding-bottom: 35px;
  -webkit-filter: brightness(1.60);
  filter: brightness(1.60);
}

.terminal1 {
  position: relative;
  background: rgba(62,0,0,.40);
  box-shadow: 9px 9px 12px 2px rgb(95,109,136);
  z-index: 1;
/*
  -webkit-filter: blur(2px);
  filter: blur(2px);*/
}

.terminal2-body {
  overflow: auto;
  height: 60vh;
  position: relative;
  padding-left: 16px;
  padding-bottom: 35px;
  -webkit-filter: brightness(1.60);
  filter: brightness(1.60);
  scrollbar-width: thin;          /* "auto" or "thin"  */
  scrollbar-color: rgb(16,139,37) rgb(136, 106, 123);
}

.terminal2 {
  position: relative;
  background: rgba(62,0,0,.40);
  box-shadow: 9px 9px 12px 2px rgb(95,109,136);
  z-index: 1;
  margin-bottom: 4em;
/*
  -webkit-filter: blur(2px);
  filter: blur(2px);*/
}

.card-text-user2 {
  color: rgb(16,139,37);
  font-family: monaco;
  font-size: 20px;
  margin: 0px;
  padding-bottom: 9px;
}

.usedTech {
  color: rgb(8, 179, 127);
  font-style: italic
}

.projects {
  margin: 0px;
}

.dot {
  padding-left: 3px;
  padding-right: 3px;
}

.navbar {
    margin-bottom: 0px;
}



.navbar-brand {
  font-size: 2.6em;
  font-family: 'Bree serif', serif;
  font-weight: 450;
  justify-content: left;
  text-shadow: 1px 1px grey;
}


.footer {
    position: fixed;
    width: 100%;
    height: 70px;
    text-align:center;
    /*overflow: auto;
    position: sticky;*/
    bottom: 0;
    margin-bottom: 0;
    margin-top: 20px;
}

.npm {
  padding-left: 7px;
}
